a {
    text-decoration: none;
    color: inherit; /* This will inherit the color from the parent element */
}

.header {
    transition: background-color 0.35s ease;
}

.opaque {
    background-color: rgba(255, 255, 255, 0.99); /* Change to your desired background color */
}

/* Ensure the main container takes the full viewport height */
.index-page {
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1; /* This makes the main content take up remaining space */
}

.footer {
    margin-top: auto; /* Push footer to the bottom */
}

/* Scroll to top button styling */
#scroll-top {
    position: fixed;
    bottom: 20px; /* Adjust as needed */
    right: 20px; /* Adjust as needed */
    display: none; /* Initially hidden */
    z-index: 1000; /* Ensure it appears above other elements */
}

#scroll-top.active {
    display: flex; /* Show button when active */
}

